import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Florence - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/florence/"
      image="https://kiwicacahuate.com/florence/florence.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/florence.webp, /florence/florence.webp 2x" />
                <source srcSet="/florence/florence.jpg, /florence/florence.jpg 2x" />
                <img src="/florence/florence.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/001.webp, /florence/thumbnail/001-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/001.jpg, /florence/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/002.webp, /florence/thumbnail/002-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/002.jpg, /florence/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/004.webp, /florence/thumbnail/004-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/004.jpg, /florence/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/005.webp, /florence/thumbnail/005-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/005.jpg, /florence/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/006.webp, /florence/thumbnail/006-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/006.jpg, /florence/thumbnail/006-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/006.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/007.webp, /florence/thumbnail/007-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/007.jpg, /florence/thumbnail/007-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/007.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/008.webp, /florence/thumbnail/008-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/008.jpg, /florence/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/009.webp, /florence/thumbnail/009-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/009.jpg, /florence/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/florence/thumbnail/010.webp, /florence/thumbnail/010-2x.webp 2x" />
                <source srcSet="/florence/thumbnail/010.jpg, /florence/thumbnail/010-2x.jpg 2x" />
                <img
                  src="/florence/thumbnail/010.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
